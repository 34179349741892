import { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import AccountContext from '../AccountContext';
import ProviderContext from '../ProviderContext';
import { ToastContainer, toast } from "react-toastify";
import Modal from 'react-modal'; // Assuming you're using react-modal

import "../components/AlertTemplate.css"
import '../styles/Reward.css';

// ABIs
import NFTContract from '../abis/Phase2NFT.json';
import StakeContract from '../abis/NFTStake.json';

// Config
import config from '../config.json';

Modal.setAppElement('#root'); // Properly sets the app element for screen readers


function Rewards() {
  const { account } = useContext(AccountContext);
  const { provider } = useContext(ProviderContext);
  const TOKEN_DECIMALS = 5;

  const nftSets = {
    Phase2NFT: { abi: NFTContract },
    ChristmasNFT: { abi: NFTContract },
    HalloweenNFT: { abi: NFTContract },
    RaffleNFT: { abi: NFTContract },
  };

  // Assuming the same staking contract is used for all NFT sets
  const stakingContractConfig = { abi: StakeContract, addressKey: "StakeContract" };
  const secondStakingContractConfig = { abi: StakeContract, addressKey: "RaffleStakeContract" };



  // Dynamic state initialization
  const [contracts, setContracts] = useState({});
  const [balances, setBalances] = useState({});
  const [rewards, setRewards] = useState({});
  const [nfts, setNfts] = useState({});
  const [nftMetadata, setNftMetadata] = useState({});
  const [network, setNetwork] = useState(null)
  const [stakingContract, setStakingContract] = useState(null);
  const [secondStakingContract, setSecondStakingContract] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedNft, setSelectedNft] = useState({}); // For storing selected NFT for modal


  const loadBlockchainData = async () => {
    if (provider) {
      const network = await provider.getNetwork();
      setNetwork(network);

      const newContracts = {};
      const newBalances = {};
      const newRewards = {};
      const newNfts = {};

      // Initialize NFT contracts
      for (const setName of Object.keys(nftSets)) {
        const setConfig = config[network.chainId][`${setName}`];
        newContracts[setName] = new ethers.Contract(setConfig.address, nftSets[setName].abi, provider);
        newBalances[setName] = 0;
        newRewards[setName] = 0;
        newNfts[setName] = [];
        console.log(`${setName}`);
      }

      // Set all contracts at once
      setContracts(newContracts);
      setBalances(newBalances);
      setRewards(newRewards);
      setNfts(newNfts);

      fetchBalances(newContracts);

      // Initialize the first staking contract
    const stakingContractAddress = config[network.chainId][stakingContractConfig.addressKey].address;
    if (stakingContractAddress) {
      const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractConfig.abi, provider);
      setStakingContract(stakingContractInstance);
      // Optionally store in newContracts if you want to keep it alongside NFT contracts
      newContracts['stakingContract'] = stakingContractInstance;
    }

    // Initialize the second staking contract
    const secondStakingContractAddress = config[network.chainId][secondStakingContractConfig.addressKey].address;
    if (secondStakingContractAddress) {
      const secondStakingContractInstance = new ethers.Contract(secondStakingContractAddress, secondStakingContractConfig.abi, provider);
      setSecondStakingContract(secondStakingContractInstance);
      // Optionally store in newContracts
      newContracts['secondStakingContract'] = secondStakingContractInstance;
        setContracts(newContracts);
      } else {
        console.warn("Staking contract address or ABI is missing.");
      }
    }
  };


  const fetchBalances = async (contracts) => {
    try {
      const newBalances = { ...balances };
      for (const setName of Object.keys(contracts)) {
        const contract = contracts[setName];
        if (!contract || setName === "stakingContract") continue; // Skip stakingContract for balance fetching
        // Ensure the account is not null or undefined
        if (!account) {
          console.error("Account is undefined.");
          return;
        }

        const balance = await contract.balanceOf(account);
        newBalances[setName] = balance.toNumber();
      }
      setBalances(newBalances);
    } catch (error) {
      console.error("Error fetching balances:", error);
    }
  };


  // Function to fetch all NFTs the user owns and metadata for only one NFT per set
  const fetchNFTs = async () => {
    const updatedNfts = {};
    const updatedMetadata = {};

    for (const setName of Object.keys(nftSets)) {
      const contract = contracts[setName];
      if (!contract) continue;


      const tokenCount = await contract.balanceOf(account);
      if (tokenCount.toNumber() === 0) continue;

      updatedNfts[setName] = []; // Initialize the array to hold NFT tokenIds

      // Assuming you want metadata for the first token only
      if (tokenCount.toNumber() > 0) {
        const tokenId = await contract.tokenOfOwnerByIndex(account, 0); // Get first token
        const allTokenIds = await contract.walletOfOwner(account);
        updatedNfts[setName] = allTokenIds.map(id => id.toString()); // Convert BigNumber to string if necessary
        // Fetch metadata for the first tokenId
        await fetchMetadataForNFT(setName, tokenId.toString()).then((metadata) => {
          updatedMetadata[setName] = [metadata]; // Store fetched metadata
        });
      }
    }
    setNfts(updatedNfts);
    setNftMetadata(updatedMetadata); // Assuming you have a way to set metadata in your state

    await fetchRewards(updatedNfts);

  };

// Function to fetch metadata for a single NFT and return the metadata
const fetchMetadataForNFT = async (setName) => {
    try {
      const contract = contracts[setName];
      if (!contract) {
        console.error(`Contract not found for setName: ${setName}`);
        return null;
      }
  
      // Construct the URL to the metadata file
      const metadataFilePath = `/metadata/${setName}.json`;  // Ensure this path is correct
  
      // Fetch the metadata from the local file using fetch
      const response = await fetch(metadataFilePath);
      
      if (!response.ok) {
        console.error(`Failed to fetch metadata file for setName: ${setName}. Status: ${response.status}`);
        return null;
      }
  
      const metadata = await response.json().catch(error => {
        console.error(`Error parsing JSON for setName: ${setName}.`, error);
        return null;
      });
  
      if (!metadata) {
        return null;
      }
  
      console.log('Metadata fetched:', metadata);
  
      const balance = balances[setName];
      const symbol = await contract.symbol();
  
      return { setName, balance, symbol, ...metadata };
    } catch (error) {
      console.error(`Failed to fetch metadata for set ${setName}:`, error);
      return null;
    }
  };

  const fetchRewards = async (ownedTokenIds) => {
    const updatedRewards = {};

    for (const setName of Object.keys(ownedTokenIds)) {
      let setRewardAmount = ethers.BigNumber.from(0);

      // Determine which staking contract to use
      const currentStakingContract = setName === "RaffleNFT" ? secondStakingContract : stakingContract;

      for (const tokenId of ownedTokenIds[setName]) {
        try {
          // Use the selected staking contract to get unpaid earnings
          const rewardAmount = await currentStakingContract.getUnpaidEarnings(
            config[network.chainId][setName].address,
            ethers.BigNumber.from(tokenId)
          );
          setRewardAmount = setRewardAmount.add(rewardAmount);
        } catch (error) {
          console.error(`Failed to fetch rewards for tokenId ${tokenId} in set ${setName}:`, error);
          continue;
        }
      }

      updatedRewards[setName] = ethers.utils.formatUnits(setRewardAmount, TOKEN_DECIMALS);
    }

    // Update your state or UI with the new rewards
    setRewards(updatedRewards);
};

const handleClaim = async (setName) => {
  try {
    const signer = provider.getSigner();

    // Determine which staking contract to use based on setName
    const currentStakingContract = setName === "RaffleNFT" ? secondStakingContract : stakingContract;

    if (!currentStakingContract) {
      console.error("Staking contract is not available.");
      return;
    }

    const ownedTokenIds = nfts[setName]; // This should already be an array of token IDs

    // Ensure that ownedTokenIds is an array of BigNumber or string representing token IDs
    const tokenIds = ownedTokenIds.map(ethers.BigNumber.from);

    // Use the selected staking contract to claim dividends
    const transaction = await currentStakingContract.connect(signer).claimDividends(
      config[network.chainId][setName].address,
      tokenIds, // Pass the array of token IDs directly
      { gasLimit: 10000000 }
    );

    await transaction.wait();

    console.log('Rewards claimed successfully!');
    // Optionally, refresh balances and rewards here
  } catch (error) {
    console.error(`Error claiming rewards for ${setName}:`, error);
  }
};

  const claimRewards = (setName) => {
    handleClaim(setName);
  }



  const openModal = (setName, index) => {
    const metadataArray = nftMetadata[setName];
    if (metadataArray && metadataArray.length > index) {
      const metadata = metadataArray[index];
      setSelectedNft(metadata); // Set the selected NFT metadata
      setModalIsOpen(true);

    } else {
      console.error('Metadata not found for setName:', setName, 'at index:', index);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  // Similar generic functions can be created for fetching rewards, NFTs, and handling claims

  const [dataLoaded, setDataLoaded] = useState(null);

  useEffect(() => {
    if (account && provider && !dataLoaded) { // Check if the data hasn't been loaded yet
      const fetchData = async () => {

        await loadBlockchainData();
        setDataLoaded(true); // Set the flag after loading data
      }
      fetchData();

    }
  }, [account, provider, dataLoaded]); // Add the flag to the dependencies array

  useEffect(() => {
    if (account && dataLoaded === true) {
      const fetchData = async () => {
        await fetchNFTs();
      }
      fetchData();
    }
  }, [account, contracts, dataLoaded]);


  return (
    <div>
      <ToastContainer />
      <div className='totalArea'>
        {Object.keys(nftMetadata).map((setName) => (
          nftMetadata[setName].map((metadata, index) => (
            <div key={index} className="claimArea" onClick={() => openModal(setName, index)}>
              <div className='mint-claim'>{metadata.animation_url ? (
                <video className='image_claim' controls autoPlay loop muted>
                  <source src={metadata.animation_url} type="video/mp4" />
                </video>
              ) : (
                metadata.image && <img src={metadata.image} alt={metadata.name} className='image_claim' />
              )}
                <div className='text-body'>{metadata.symbol} - Rewards: {rewards[setName]}</div>
              </div>
            </div>
          ))
        ))}
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className='modal' contentLabel="NFT Details">
        {selectedNft ? (
          <div className="modal-content">
            <h2>{selectedNft.name || ''} ({selectedNft.symbol || ''})</h2>
            <p>{selectedNft.description || ''}</p>
            {selectedNft.animation_url ? (
              <p><video width="400" controls autoPlay loop muted>
                <source src={selectedNft.animation_url} type="video/mp4" />
              </video></p>
            ) : (
              selectedNft.image && <img src={selectedNft.image} alt={selectedNft.name} width="400" />
            )}
            <p>NFTs Held: {balances[selectedNft.setName]}</p>
            <p>Available Rewards: {rewards[selectedNft.setName]}</p>
            <div className='button-group'>
              <button onClick={() => claimRewards(selectedNft.setName)} className='mint_button'>Claim Rewards!</button>
              <button onClick={closeModal} className='mint_button'>Close</button></div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </Modal>
    </div>
  );
}

export default Rewards;