import { useState, useEffect, useContext } from 'react';
import { ethers, Networkish, BlockTag, providers } from 'ethers';
import AccountContext from '../AccountContext';
import ProviderContext from '../ProviderContext';
import ReactModal from 'react-modal';
import { useParams } from 'react-router-dom';


import { ToastContainer, toast } from "react-toastify";
import "../components/AlertTemplate.css"

// ABIs
import MintContract from '../abis/NFTMinter.json';

// Config
import config from '../config.json';


const RPC_ENDPOINT = "https://scan.maxxchain.org/api/eth-rpc"; 

class MaxxChainProvider extends ethers.providers.JsonRpcProvider {
  constructor() {
      super("https://scan.maxxchain.org/api/", {
          name: 'maxxchain',
          chainId: 0x27d9, // Chain ID for MaxxChain
          // any other network specific details if necessary
      });
  }
}


function Collection() {
  const [mintContract, setMintContract] = useState(null);
  const [balance, setBalance] = useState('');
  const [supply, setTotalSupply] = useState('');
  const { account } = useContext(AccountContext);
  const { provider } = useContext(ProviderContext);
  const [text] = useState('0x22cfCEf0bc711515283f7aC9371c0c8dd77C4B4F');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nftDetails, setNftDetails] = useState({ name: '', artwork: '' });
  const address = "0x7a76Af427A094491045FEBeC066c4A7a8eebC207";
  const [tokenName, setTokenName] = useState('');

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ image: '', name: '', description: '' });
  const { contractAddress } = useParams();
  const [allNfts, setNfts] = useState([]); // Stores all NFTs
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  const pageSize = 15; // Number of NFTs per page

  const indexOfLastNft = currentPage * pageSize;
  const indexOfFirstNft = indexOfLastNft - pageSize;
  const currentNfts = allNfts.slice(indexOfFirstNft, indexOfLastNft);


  const loadBlockchainData = async () => {
    if (provider) {
      const network = await provider.getNetwork();

      
           
    }
  };

  const updateData = async () => {

        getCollectionNFTs(contractAddress);
        console.log(contractAddress);
};

const getCollectionNFTs = async (contractAddress) => {
    console.log("Starting getCollectionNFTs function...");
  
    // Creating contract instance
    const contract = new ethers.Contract(contractAddress, [
      "function totalSupply() external view returns (uint256)",
      "function tokenURI(uint256 tokenId) external view returns (string)",
      "function name() external view returns (string)"
    ], provider);
  
    try {
      // Get total supply of NFTs
      const totalSupply = await contract.totalSupply();
      const name = await contract.name();
      setTokenName(name);
  
      // Initialize an array to store NFTs
      let nftsHeld = [];
  
      // Iterate over each NFT by its tokenId
      for (let i = 1; i <= totalSupply; i++) {
        try {
          // Fetch token URI
          const tokenURI = await contract.tokenURI(i);
  
          // Process the tokenURI to fetch metadata (assuming IPFS here)
          const httpsURL = tokenURI.replace("ipfs://", "https://ipfs.io/ipfs/");
          const response = await fetch(httpsURL);
          const metadata = await response.json();
  
          // Push metadata to array (adjust as needed)
          if (metadata.image) {
            const imageIPFS = metadata.image;
            const imageLink = imageIPFS.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/");
            nftsHeld.push({ name: metadata.name, tokenId: i, imageLink });
          }
  
        } catch (error) {
          console.error(`Error fetching data for token ID ${i}:`, error);
        }
      }
  
      console.log("Final NFTs in Collection:", nftsHeld);
      setNfts(nftsHeld); // Set the state with the fetched NFTs
  
    } catch (error) {
      console.error("Error in getCollectionNFTs function:", error);
    }
  };
  
  

const openModal = (image, name, description) => {
  setModalContent({ image, name, description });
  setModalIsOpen(true);
};

const closeModal = () => {
  setModalIsOpen(false);
};

const previousPage = () => {
  setCurrentPage(currentPage - 1);
};

const nextPage = () => {
  setCurrentPage(currentPage + 1);
};

function NFTModal({ isOpen, details, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <h2 className='text-header'>{details.name}</h2>
        <video width="400" height="400" controls autoPlay loop muted>
                <source src={nftDetails.artwork} type="video/mp4"/>
                Your browser does not support the video tag.
          </video>
        <button className="mint_button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
}


  useEffect(() => {
    loadBlockchainData();
  }, [provider]);

  useEffect(() => {
    if (account) {
      updateData();
    }
  }, [account, mintContract]);

  return (
    <div>
      <ToastContainer/>
  
      <div className="claimArea">
        <div className="mint">
          <h1 className='header'>{tokenName}</h1>
        </div>
        
        <div className="gallery-container">
          {currentNfts.map((nftAsset, index) => {
            if (nftAsset.imageLink.endsWith('.mp4')) {
              return (
                <button key={index} onClick={() => {
                  setIsModalOpen(true);
                  setNftDetails({ name: nftAsset.name, TokenID:`Token #${nftAsset.tokenId}`, artwork: nftAsset.imageLink });
                  
                }}className="nft-container">
                  <video autoPlay loop >
                  <source src={nftAsset.imageLink} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </button>
              );
            } else {
              return (
                <img 
                  key={nftAsset.tokenId} 
                  src={nftAsset.imageLink}
                  alt="NFT" 
                  style={{ width: '200px', margin: '10px' }}
                  className='nft-container'
                  onClick={() => {
                    setIsModalOpen(true);
                    setNftDetails({ name: `Token #${nftAsset.tokenId}`, artwork: nftAsset.imageLink });
                  }} 
                />
              );
            }
          })}
        </div>

        <div className="pagination-controls">
            <button onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}>
            Previous
            </button>
            <span>Page {currentPage}</span>
            <button onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(allNfts.length / pageSize)))}>
            Next
            </button>
        </div>
  
        <NFTModal 
          isOpen={isModalOpen} 
          details={nftDetails} 
          onClose={() => setIsModalOpen(false)}
        />
      </div>
    </div>
  );
  

}

export default Collection;
