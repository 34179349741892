import React, { useState, useEffect } from 'react';
import './NFTCard.css';

function NFTCard({ asset, onCardClick }) {
  const { tokenId, name, description, video, image } = asset;

  return (
    <div>
    <div onClick={() => onCardClick(name, `Token #${tokenId}`, video)} className="nft-container">
      {
        <img src={image} width="400" height="400" alt={name} />
        
      }
      <div>
        <p className='text-header'>{name}</p>
        <h1 className='text-body'>{description}</h1>
        <h1 className='text-body'>(Click image to open your Christmas Box)</h1>
      </div>
      </div>
    </div>
    

  );
}

export default NFTCard;
