import { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import AccountContext from '../AccountContext';
import ProviderContext from '../ProviderContext';

import { ToastContainer, toast } from "react-toastify";
import "../components/AlertTemplate.css"
import '../styles/TierReward.css';


// ABIs
import NFTContract from '../abis/Phase2NFT.json';
import TierReward from '../abis/TierReward.json';


// Config
import config from '../config.json';

function TierRewards() {
    const [nftContract, setNFTContract] = useState(null);
    const [stakeContract, setStakeContract] = useState(null);
    const [totalRewards, setTotalRewards] = useState(0);
    const [nft, setNft] = useState([]);
    const [rewards, setRewards] = useState(null);
    const [animationUrl, setAnimationUrl] = useState(null);
    const { account } = useContext(AccountContext);
    const { provider } = useContext(ProviderContext);


    const loadBlockchainData = async () => {
        if (provider) {
            const network = await provider.getNetwork();

            const nftContract = new ethers.Contract(
                config[network.chainId].TierNFT.address,
                NFTContract,
                provider
            );
            setNFTContract(nftContract);

            const stakeContract = new ethers.Contract(
                config[network.chainId].TierReward.address,
                TierReward,
                provider
            );
            setStakeContract(stakeContract);
        }
    };

    const updateData = async () => {
        const network = await provider.getNetwork();
        if (NFTContract && stakeContract && account) {
            const ownedNFTs = await nftContract.walletOfOwner(account);
            if (ownedNFTs.length > 0) {
                const singleTokenId = ownedNFTs[0]; // Access the first (and presumably only) element
                console.log(singleTokenId.toString());
            } else {
                console.log("No NFTs owned by the account");
            }


            const rewards = await fetchRewards(ownedNFTs);
            setRewards(rewards);

            const userTotal = await getTotalRewards();
            setTotalRewards(userTotal);
            console.log(userTotal);

            fetchNFTsInfo();

        }
    }

    const TOKEN_DECIMALS = 5;

    function formatTokenAmount(amountInWei) {
        return ethers.utils.formatUnits(amountInWei, TOKEN_DECIMALS);
    }

    const getTotalRewards = async () => {
        const userRewards = await stakeContract.cumulativeRewards(account);
        return formatTokenAmount(userRewards);
    }


    const fetchRewards = async (tokenIds) => {
        let totalRewards = ethers.BigNumber.from(0);

        for (const tokenId of tokenIds) {
            const rewardAmount = await stakeContract.getUnpaidEarnings(tokenId);
            totalRewards = totalRewards.add(rewardAmount);
        }

        return formatTokenAmount(totalRewards);
    };


    const fetchNFTs = async () => {

        const tokenId = await nftContract.walletOfOwner(account);
        console.log(tokenId);
        return tokenId;
    };

    const fetchNFTsInfo = async () => {
        const nfts = [];
        const tokenIds = await nftContract.walletOfOwner(account); // Assuming this returns an array of tokenIds

        for (const tokenId of tokenIds) {
            try {
                const tokenUri = await nftContract.tokenURI(tokenId);
                console.log(tokenUri);

                // Store each NFT's info, including its tokenId and animationUrl
                nfts.push({
                    tokenId: tokenId.toString(), // Convert tokenId to string if it's a BigNumber
                    animationUrl
                });

                // Optionally set the animation URL of the first NFT to state
                // Consider how you want to handle multiple animation URLs
                if (nfts.length === 1) {
                    setAnimationUrl(tokenUri);
                }

                console.log(tokenId.toString(), tokenUri);
            } catch (error) {
                console.error('Error fetching NFT metadata for tokenId:', tokenId.toString(), error);
            }
        }

        // If you want to store all fetched NFTs information in state, do it here
        // For example: setNfts(nfts);

        return nfts;
    };


    const handleClaim = async () => {
        await claimRewards();
        updateData();
    }

    const claimRewards = async () => {
        try {
            const signer = await provider.getSigner();

            // Fetch tokenIds that the user owns and wishes to claim rewards for
            const ownedTokenIds = await fetchNFTs(); // This function needs to return an array of token IDs

            // Call the claimDividends function from the smart contract
            const transaction = await stakeContract.connect(signer).claimDividends(ownedTokenIds, {
                gasLimit: 10000000 // Adjust gas limit as necessary
            });

            await transaction.wait();

            // Display success message
            toast.success('Success!', { className: 'alert-template', position: toast.POSITION.TOP_CENTER, closeButton: false });
        } catch (error) {
            console.error(error); // Log the error
            // Error handling
            if (error.message.includes('user rejected transaction')) {
                toast.error('User rejected transaction', { className: 'alert-template', closeButton: false });
            } else {
                toast.error(`Blockchain interaction failed: ${error.message}`, { className: 'alert-template', closeButton: false });
            }
        }
    };


    useEffect(() => {
        loadBlockchainData();
    }, [provider]);

    useEffect(() => {
        if (account && nftContract && stakeContract) {
            updateData();
        }
    }, [account, nftContract, stakeContract]);

    return (
        <div>
            <ToastContainer />

            <div className='container'>
                <div className="claimArea">
                    <div className="mint">
                        <h1 className='big-header'>
                            Level-Up NFT Rewards
                        </h1>
                        <div className='imageTier'>
                            <img src={animationUrl} />
                        </div>

                        <div>

                            <h1 className='header'>Total SafuMaxx Claimed:</h1>
                            <h1 className='text-body'>{totalRewards} SafuMaxx</h1>
                            <h2 className='text-header'>Available Rewards:</h2>
                            <h3 className='text-body'>{rewards} SafuMaxx</h3>
                        </div>

                        <button
                            type="button"
                            className="mint_button"
                            onClick={handleClaim}
                        >
                            Claim
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TierRewards;
