import { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter, useLocation } from 'react-router-dom';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AccountContext from './AccountContext.js';
import ProviderContext from './ProviderContext.js';


import Claim from './routes/Claim.js';

import UserMint from './routes/UserMint.js';

import Mint from './routes/Mint.js';

import Reward from './routes/RewardTemp.js';

import RewardBro from './routes/RewardBro.js';


import Swap from './routes/swap.js';

import Gallery from './routes/Gallery.js';

import Navigation from './components/Navigation';

import Collection from './routes/Collection';

import TierRewards from './routes/TierReward';

import Factory from './routes/Factory'


import { Web3Provider } from '@ethersproject/providers';

import BackgroundWrapper from './components/BackgroundWrapper'; // Import the component

function App() {
  const [account, setAccount] = useState(null);
  const [provider, setProvider] = useState(null);


  useEffect(() => {
    const setupWeb3Provider = async () => {
      if (window.ethereum) {
        const web3Provider = new Web3Provider(window.ethereum);
        setProvider(web3Provider);
      }
    };

    setupWeb3Provider();
  }, []);

  return (
  <BrowserRouter>
    <BackgroundWrapper>
      <AccountContext.Provider value={{ account, setAccount }}>
        <ProviderContext.Provider value={{ provider, setProvider }}>
          <Navigation />
          <Routes>
            <Route path="/" element={<Claim />} />
            <Route path="/mint" element={<Mint />} />
            <Route path="/claim" element={<Reward />} />

            <Route path="/bro" element={<RewardBro />} />
            <Route path="/christmas" element={<Gallery />}/>
            <Route path="/TierClaim" element={<TierRewards />}/>
            {  /*<Route path="/Create" element={<Factory />}/>
            <Route path="/swap" element={<Swap />} />
              <Route path="/collection/:contractAddress" element={<Collection />} />
              <Route path="/usermint" element={<UserMint />} />*/}
          </Routes>

          <ToastContainer />

        </ProviderContext.Provider>
      </AccountContext.Provider>
    </BackgroundWrapper>
  </BrowserRouter>
  );
}

export default App;
