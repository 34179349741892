import { useState, useEffect, useContext, useCallback, useRef } from "react";
import { ethers } from "ethers";
import AccountContext from "../AccountContext";
import ProviderContext from "../ProviderContext";

import { ToastContainer, toast } from "react-toastify";
import { Web3Storage } from "web3.storage";
import { Buffer } from "buffer";
import "../components/AlertTemplate.css";

// ABIs
import MintContract from "../abis/NFTMinter.json";

// Config
import config from "../config.json";

function getStorageClient() {
  return new Web3Storage({
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGI4Yjk3OUFGNDQ5ZTNBMTU4MzZkNWQ0YUU3ZkIxOGE0QjM2NjU2YUEiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2OTY1NDc1NzI4ODIsIm5hbWUiOiJNYXJrZXRwbGFjZSJ9.hMgxP-3eW_jm4UKBB2Xcunu1IOoFNu8JmPB1PL5Yiy8",
  });
}

function UserMint() {
  const [mintContract, setMintContract] = useState(null);
  const { account } = useContext(AccountContext);
  const { provider } = useContext(ProviderContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nftDetails, setNftDetails] = useState({
    name: "",
    description: "",
    artwork: "",
    metadata: "",
  });
  const [file, setFile] = useState(null);
  const [previewSrc, setPreviewSrc] = useState("");
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [api] =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGI4Yjk3OUFGNDQ5ZTNBMTU4MzZkNWQ0YUU3ZkIxOGE0QjM2NjU2YUEiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2OTY1NDc1NzI4ODIsIm5hbWUiOiJNYXJrZXRwbGFjZSJ9.hMgxP-3eW_jm4UKBB2Xcunu1IOoFNu8JmPB1PL5Yiy8";

  const loadBlockchainData = async () => {
    if (provider) {
      const network = await provider.getNetwork();

      const mintContract = new ethers.Contract(
        config[network.chainId].NFT.address,
        MintContract,
        provider
      );
      setMintContract(mintContract);
    }
  };

  const updateData = async () => {
    if (mintContract) {
    }
  };

  const captureFile = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      setFile(file); // Store the File object
      setFileName(file.name); // Set the file name
    } else {
      console.error("No file selected");
    }
  };

  const onFileChange = useCallback((event) => {
    event.preventDefault();
    const uploadedFile =
      event.type === "drop"
        ? event.dataTransfer.files[0]
        : event.target.files[0];
    setFile(uploadedFile);
    console.log(file);

    setFileName(uploadedFile.name); // Set the file name

    const objectUrl = URL.createObjectURL(uploadedFile);
    setPreviewSrc(objectUrl);
  }, []);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const createMetadata = (name, description, imageUrl) => {
    return {
      name: name,
      description: description,
      image: imageUrl,
    };
  };

  const handleUploadAndMint = async () => {
    try {
      const client = getStorageClient();

      // Upload image to IPFS and get its URI
      const imageCID = await client.put([file]); // assuming 'file' contains the image file
      console.log({ fileName });
      const imageUrl = `https://${imageCID}.ipfs.w3s.link/${fileName}`;
      console.log(imageUrl);

      // Create metadata JSON
      const metadata = createMetadata(
        name, // replace with your actual token name
        description, // replace with your actual token description
        imageUrl
      );

      console.log(metadata);
      // Convert metadata object to buffer
      const metadataBuffer = new TextEncoder().encode(JSON.stringify(metadata));
      const metadataBlob = new Blob([metadataBuffer], {
        type: "application/json",
      });
      console.log(metadataBuffer);
      console.log(metadataBlob);
      const metadataFile = new File([metadataBlob], "metadata.json", {
        type: "application/json",
      });
      console.log(metadataFile);
      const metadataCID = await client.put([metadataFile]);
      const metadataUrl = `https://${metadataCID}.ipfs.w3s.link/metadata.json`; // or however your IPFS gateway structures URLs

      console.log(metadataUrl);

      // Now call your minting function with the metadataUrl as the tokenURI
      // await mintNFT(metadataUrl);

      setNftDetails({
        name: metadata.name, // You can customize this if you know more details
        description: metadata.description,
        artwork: imageUrl,
        metadata: metadataUrl,
      });
      setIsModalOpen(true);
      setPreviewSrc("");
      setFile(null);
      setName("");
      setDescription("");
      // updateData(); // Ensure you have this function defined
    } catch (error) {
      console.error("Error uploading file and minting NFT:", error);
    }
  };

  const handleMintNFT = async () => {
    await mintNFT();
    updateData();
  };

  const mintNFT = async (ipfsUrl) => {
    try {
      const signer = await provider.getSigner();
      const transaction = await mintContract.connect(signer).mint({
        name: `Token #${nftId}`, // customize as needed
        artwork: ipfsUrl,
        gasLimit: 300000,
        value: ethers.utils.parseEther("5000".toString()),
      });
      await transaction.wait();

      // Assuming the minted NFT has a sequential ID and the latest ID is the total supply
      // Assuming the minted NFT has a sequential ID and the latest ID is the total supply
      const nftId = await mintContract.totalSupply();

      // Construct the image URL directly
      const imageUrl = `https://cloudflare-ipfs.com/ipfs/bafybeielo3bpsec6swggylzlloduqrvdwm6f34lgnnqccec7vnhanhgvba/Profit_Pass.mp4`;

      setNftDetails({
        name: `Token #${nftId}`, // You can customize this if you know more details
        artwork: imageUrl,
      });
      setIsModalOpen(true);

      toast.success("Success!", {
        className: "alert-template",
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
      });
    } catch (error) {
      console.error(error); // This will log the error object to your console
      if (error.message.includes("user rejected transaction")) {
        toast.error(`User rejected transaction`, {
          className: "alert-template",
          closeButton: false,
        });
      } else {
        toast.error(`Blockchain interaction failed: ${error.message}`, {
          className: "alert-template",
          closeButton: false,
        });
      }
    }
  };

  const onBrowseButtonClick = useCallback(() => {
    // Use useCallback to memoize the function
    fileInputRef.current.click(); // Programmatically trigger a click event on the file input
  }, []);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  // Event handler for the description input field
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  function NFTModal({ isOpen, onClose, nftDetails }) {
    // make sure to pass nftDetails as a prop
    if (!isOpen) return null;

    const renderMedia = () => {
      if (nftDetails && nftDetails.artwork) {
        console.log(nftDetails);
        const fileExtension = nftDetails.artwork.split(".").pop();
        if (
          fileExtension === "mp4" ||
          fileExtension === "webm" ||
          fileExtension === "mov"
        ) {
          return (
            <video width="400" height="400" controls autoPlay loop muted>
              <source src={nftDetails.artwork} type={`video/mp4`} />
              Your browser does not support the video tag.
            </video>
          );
        } else {
          return (
            <img
              src={nftDetails.artwork}
              alt={nftDetails.name}
              width="400"
              height="400"
            />
          );
        }
      } else {
        return <p>No artwork available</p>;
      }
    };

    return (
      <div className="modal">
        <div className="modal-content">
          <h2 className="text-header">{nftDetails ? nftDetails.name : ""}</h2>
          <h2 className="text-header">
            {nftDetails ? nftDetails.description : ""}
          </h2>
          {renderMedia()}
          <button className="mint_button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    );
  }

  useEffect(() => {
    loadBlockchainData();
  }, [provider]);

  useEffect(() => {
    if (account && mintContract) {
      updateData();
    }
  }, [account, mintContract]);

  return (
    <div>
      <ToastContainer />

      <NFTModal
        isOpen={isModalOpen}
        nftDetails={nftDetails}
        onClose={() => setIsModalOpen(false)}
      />

      <div className="claimArea">
        <div className="userMint">
          <h1 className="header">Mint an NFT</h1>
          <div>
            <h2 className="text-header">NFT Cost:</h2>
            <h3 className="text-body">5,000 PWR</h3>
            <div></div>
          </div>

          <div>
            {previewSrc == "" && (
              <div
                className="dropzone"
                onDrop={onFileChange}
                onDragOver={onDragOver}
                onDragEnter={() =>
                  document.querySelector(".dropzone").classList.add("dragover")
                }
                onDragLeave={() =>
                  document
                    .querySelector(".dropzone")
                    .classList.remove("dragover")
                }
              >
                {" "}
                <p className="dropzone-header">
                  Select the Artwork For Your NFT{" "}
                </p>
                <p className="dropzone-body">Drag & Drop file here or</p>
                <label>
                  <button
                    onClick={onBrowseButtonClick} // Set the click handler to the new function
                    style={{
                      textDecoration: "underline",
                      marginLeft: "5px",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                  >
                    browse
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef} // Set the reference to the file input
                    onChange={onFileChange}
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: "0",
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: "-1",
                    }}
                  />
                </label>
              </div>
            )}
            {file && (
              <div>
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  {file && file.type.startsWith("image/") ? (
                    <img
                      src={previewSrc}
                      alt="Preview"
                      style={{
                        width: "300px",
                        height: "auto",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        padding: "5px",
                      }}
                    />
                  ) : file && file.type.startsWith("video/") ? (
                    <video
                      controls
                      width="300" /* Adjust as necessary */
                      src={previewSrc}
                      autoPlay
                      style={{
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        padding: "5px",
                      }}
                    >
                      Your browser does not support the video tag.
                    </video>
                  ) : null}
                  <p>Preview of selected image</p>

                  <div className="button-container">
                  <div style={{flexDirection: "column"}}>
                  <p className="text-header">NFT Name:</p>
                  <input
                    type="text"
                    id="nftName"
                    name="nftName"
                    value={name}
                    onChange={handleNameChange}
                    className="nft_input"
                    style = {{marginRight: "10px"}}
                  /></div>
                  <div style={{flexDirection: "column"}}>
                  <p className="text-header">NFT Description:</p>
                  <input
                    type="text"
                    id="nftDescription"
                    name="nftDescription"
                    value={description}
                    onChange={handleDescriptionChange}
                    className="nft_input"
                  />
                  </div>
                  </div>
                </div>
                <div>
                  <div className="button-container">
                    <button
                      onClick={() => {
                        setPreviewSrc("");
                        setFile(null);
                      }}
                      className="mint_button"
                      style={{ margin: "0px", marginRight: "20px" }}
                    >
                      Change Image
                    </button>
                    <button
                      className="mint_button"
                      style={{ margin: "0px" }}
                      onClick={handleUploadAndMint}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserMint;
