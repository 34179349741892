import { ethers } from 'ethers';
import { useEffect, useContext, useState } from 'react';
import logo from '../logo.png';
import AccountContext from '../AccountContext';
import { Link } from 'react-router-dom';
import ProviderContext from '../ProviderContext';
import Modal from 'react-modal';

Modal.setAppElement('#root');


const Navigation = () => {
  const { account, setAccount } = useContext(AccountContext);
  const { provider, setProvider } = useContext(ProviderContext);
  const [buttonLabel, setButtonLabel] = useState('Connect');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(false);
  };

  const connectMetamask = async () => {
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = ethers.utils.getAddress(accounts[0]);
    setAccount(account);
  };

  const toggleNav = (e) => {
    setSidebarOpen(!sidebarOpen);
    e.stopPropagation();
  };

  const handleChainChanged = (chainId) => {
    console.log(`Chain changed to ${chainId}`);
  };

  const handleAccountsChanged = (accounts) => {
    console.log('Accounts changed:', accounts);
  };

  const handleConnect = (payload) => {
    console.log('Connected:', payload);
  };

  const handleDisconnect = (payload) => {
    console.log('Disconnected:', payload);
    if (window.ethereum) {
      window.ethereum.removeAllListeners();
      setAccount(null);
      setProvider(null);
    } else {
    }
  };


  useEffect(() => {
    if (provider) {
      provider.on('chainChanged', handleChainChanged);
      provider.on('accountsChanged', handleAccountsChanged);
      provider.on('connect', handleConnect);
      provider.on('disconnect', handleDisconnect);

      return () => {
        provider.off('chainChanged', handleChainChanged);
        provider.off('accountsChanged', handleAccountsChanged);
        provider.off('connect', handleConnect);
        provider.off('disconnect', handleDisconnect);
      };
    }
  }, [provider]);

  useEffect(() => {
    if (account) {
      setButtonLabel(account.slice(0, 6) + '...' + account.slice(38, 42));
    } else {
      setButtonLabel('Connect');
    }
  }, [account]);

  return (
    <div>
      <nav>
        <div className="nav__brand">
          <img src={logo} className="logo" />
          <h1 className='navHeader'>SafuMaxx</h1>
        </div>

        <button className='nav__toggle' onClick={toggleNav}>
          ☰
        </button>

        <button
          type="button"
          className='nav__connect'
          onClick={connectMetamask}
        >{buttonLabel}</button>

        <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>

          <button className='nav__toggleclose' onClick={toggleSidebar}>
            &times;
          </button>

          <div className="nav_links_sidebar">
            <button
              type="button"
              className='sidebar_connect'
              onClick={connectMetamask}
            >
              {buttonLabel}
            </button>
            <Link to="/">
              <button className="navlinks_button">Dashboard</button>
            </Link>
            <Link to="/mint">
            <button className="navlinks_button">Mint</button>
            </Link>
            <Link to="/claim">
              <button className="navlinks_button">Claim</button>
            </Link>
            {/*<Link to="/Gallery">
            <button className="navlinks_button">Gallery</button>
          </Link>
          <Link to="/usermint">
            <button className="navlinks_button">User Mint</button>
      </Link>*/}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;


