import { useState, useEffect, useContext } from "react";
import { ethers, Networkish, BlockTag, providers } from "ethers";
import AccountContext from "../AccountContext";
import ProviderContext from "../ProviderContext";
import NFTCard from "../components/NFTCard"; // Adjust the import path as needed

import { ToastContainer, toast } from "react-toastify";
import "../components/AlertTemplate.css";

// ABIs
import NFTContract from '../abis/Phase2NFT.json';

// Config
import config from '../config.json';


function Gallery() {
  const [nftContract, setNFTContract] = useState(null);
  const { account } = useContext(AccountContext);
  const { provider } = useContext(ProviderContext);
  const [nfts, setNfts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nftDetails, setNftDetails] = useState({
    tokenId: "",
    name: "",
    description: "",
    video: "",
    image: ""
  });

  const loadBlockchainData = async () => {
    if (provider) {
      const network = await provider.getNetwork();

      const nftContract = new ethers.Contract(
        config[network.chainId].NFT.address,
        NFTContract,
        provider
      );
      setNFTContract(nftContract);
    }
  };

  const updateData = async () => {
    if (account) {
      getUserNFTs(account, provider);
    }
  };

  const getUserNFTs = async (account, provider) => {
    console.log("Starting getUserNFTs function...");



    let balance;
    try {
      balance = await nftContract.balanceOf(account);
      console.log(`Balance for ${account}:`, balance.toString());
    } catch (error) {
      console.error(`Error fetching balance for ${account}:`, error);
      return;
    }


    let nftsHeld = [];
    for (let index = 0; index < balance; index++) {
      try {
        const tokenId = await nftContract.tokenOfOwnerByIndex(account, index);
        const tokenURI = await nftContract.tokenURI(tokenId);
        const metadata = await fetchMetadata(tokenURI);

        if (metadata) {
          nftsHeld.push({
            tokenId: tokenId.toString(),
            name: metadata.name,
            description: metadata.description,
            video: metadata.animation_url,
            image: metadata.image, // if you also want to store the image URL
          });

          setNftDetails({
            tokenId: tokenId.toString(),
            name: metadata.name,
            description: metadata.description,
            video: metadata.animation_url,
            image: metadata.image, // if you also want to store the image URL
          });
          console.log(metadata.animation_url);
        }
      } catch (error) {
        console.error(`Error fetching data for token ID at index ${index}:`, error);
        continue;
      }
    }
    

    console.log("Final NFTs Held:", nftsHeld);
    setNfts(nftsHeld); // Update your state or handle the list of NFTs as needed
  };

  const fetchMetadata = async (uri) => {
    try {
      const response = await fetch(uri);
      if (!response.ok) throw new Error(`Error fetching metadata: ${response.status}`);
      return await response.json();
    } catch (error) {
      console.error('Error fetching token metadata:', error);
      return null;
    }
  };

  
  const handleCardClick = () => {
    setIsModalOpen(true);
  };

  function NFTModal({ isOpen, details, onClose }) {
    if (!isOpen) return null;

    return (
      <div className="modal">
        <div className="modal-content">
          <h2 className="text-present">{nftDetails.name}</h2>
          <video className="video" margin-left="10px" margin-right="10px" controls autoPlay loop muted>
            <source src={nftDetails.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button className="mint_button" onClick={onClose}>
            Close
          </button>
          
        </div>
      </div>
    );
  }
  
    useEffect(() => {
    loadBlockchainData();
  }, [provider]);

  useEffect(() => {
    if (account) {
      updateData();
    }
  }, [account]);

  return (
    <div>
      <ToastContainer />
      <div className="claim">
        {account ? (
          nfts.length > 0 ? (
            nfts.map((nftAsset, index) => (
              <NFTCard key={index} asset={nftAsset} onCardClick={handleCardClick} />
            ))
          ) : (
            <h1 className="header">No NFTs found in your account.</h1>
          )
        ) : (
          <h1 className="header">Please connect your wallet.</h1>
        )}
      </div>
      <NFTModal
          isOpen={isModalOpen}
          details={nftDetails}
          onClose={() => setIsModalOpen(false)}
        />
    </div>
  );
}

export default Gallery;
