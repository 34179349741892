// components/BackgroundWrapper.js
import React from 'react';
import useCustomRouteMatch from '../hooks/useRouteMatch';

function BackgroundWrapper({ children }) {
  const isMintRoute = useCustomRouteMatch(['/mint']);

  const backgroundClass = isMintRoute ? 'background' : 'background';
  
  return <div className={backgroundClass}>{children}</div>;
}

export default BackgroundWrapper;
