import { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import AccountContext from '../AccountContext';
import ProviderContext from '../ProviderContext';


import { ToastContainer, toast } from "react-toastify";
import "../components/AlertTemplate.css"

// ABIs
import MintContract from '../abis/NFTMinter.json';
import AGTContract from '../abis/AGT.json';
import FactoryABI from '../abis/Factory.json';

// Config
import config from '../config.json';

function Mint() {
  const { account } = useContext(AccountContext);
  const { provider } = useContext(ProviderContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nftDetails, setNftDetails] = useState({ name: '', artwork: '' });

  const [FactoryContract, setFactoryContract] = useState(null);
  const [nftContracts, setNftContracts] = useState([]);

  const [isDetailedModalOpen, setIsDetailedModalOpen] = useState(false);
  const [detailedNftDetails, setDetailedNftDetails] = useState({
    name: '',
    description: '',
    image: '',
    animationUrl: '',
  });

  // List of contract addresses that do not require a quantity parameter
  const contractsWithoutMultipleMint = [
    '0xdc254d4CC3ED62fE980d47f403f3d026ACAb06B1',
    '0x68010a5B8bF456d515cc81c012977873c62137DA',
    '0x4C5B5928B0a397d4602Bd2ff8D583E36284354d6'
  ];

  const contractsWithoutMaxWallet = [
    '0xf51bC506BA2551C9e85EE24b22b8CbF2DE8A146e',
    '0x6756cF34BB93cdA19bc49736E11784ce03CD8c7c'
  ];

  const contractsWithoutJSON = [
    '0x68010a5B8bF456d515cc81c012977873c62137DA',
    '0x4C5B5928B0a397d4602Bd2ff8D583E36284354d6'
  ];

  const loadBlockchainData = async () => {
    if (provider) {
      const network = await provider.getNetwork();

      const FactoryContract = new ethers.Contract(
        config[network.chainId].NFTFactory.address,
        FactoryABI,
        provider
      );
      setFactoryContract(FactoryContract);

    }
  }


  const updateData = async () => {
    if (FactoryContract) {
      updateNftData();
    }
  };

  const updateNftData = async () => {
    if (!FactoryContract || !provider) return; // Ensure FactoryContract and provider are loaded

    try {
      const collections = await FactoryContract.getCollections();
      const nftDetails = await Promise.all(collections.map(async (address) => {
        const nftContract = new ethers.Contract(address, MintContract, provider);
        let name, symbol, totalSupply, cost, maxWallet, maxSupply, metadata;

        try {
          name = await nftContract.name();
        } catch (error) {
          console.error(`Error fetching name for contract at ${address}:`, error);
          name = 'Unknown';
        }

        try {
          symbol = await nftContract.symbol();
        } catch (error) {
          console.error(`Error fetching name for contract at ${address}:`, error);
          name = 'Unknown';
        }

        try {
          totalSupply = await nftContract.totalSupply();
        } catch (error) {
          console.error(`Error fetching totalSupply for contract at ${address}:`, error);
          totalSupply = ethers.BigNumber.from(0);
        }

        try {
          cost = await nftContract.cost();
          cost = ethers.utils.formatEther(cost); // Convert Wei to Ether as a string
          cost = Math.floor(parseFloat(cost)).toString(); // No decimal places
        } catch (error) {
          console.error(`Error fetching cost for contract at ${address}:`, error);
          cost = '0'; // Representing 0 Ether as a string if fetching cost fails
        }

        if (contractsWithoutMaxWallet.includes(address)) {
          maxWallet = 'No Limit';
        } else {
          try {
            maxWallet = await nftContract.maxWallet();
          } catch (error) {
            console.error(`Error fetching maxWallet for contract at ${address}:`, error);
            maxWallet = 'No Limit'; // You may choose to set this to 'Error' or any other placeholder value that indicates the call failed.
          }
        }

        try {
          maxSupply = await nftContract.maxSupply();
        } catch (error) {
          console.error(`Error fetching maxSupply for contract at ${address}:`, error);
          maxSupply = ethers.BigNumber.from(0);
        }

        try {
          // Fetch NFT metadata for a specific tokenId, e.g., tokenId = 1
          metadata = await fetchNftMetadata(nftContract, 1);
        } catch (error) {
          console.error(`Error fetching metadata for contract at ${address}:`, error);
          metadata = {
            symbol: '',
            name: '',
            description: 'No metadata found',
            image: '',
            animationUrl: ''
          };
        }

        return {
          address,
          name,
          symbol,
          totalSupply: totalSupply.toString(),
          cost: cost,
          maxWallet: maxWallet ? maxWallet.toString() : 'No Limit',
          maxSupply: maxSupply.toString(),
          // Include fetched metadata in the result
          metadata: metadata,
        };
      }));

      setNftContracts(nftDetails);
    } catch (error) {
      console.error("Error fetching NFT data:", error);
    }
  };




  const handleMintFactoryNFT = async (NFTAddress, Cost) => {
    await mintFactoryNFT(NFTAddress, Cost);
    updateData();
  }

  const fetchNftMetadata = async (nftContract, tokenId) => {
    try {
      let tokenUri = await nftContract.tokenURI(tokenId);
      const isDirectAnimationUrl = contractsWithoutJSON.includes(nftContract.address);

      if (isDirectAnimationUrl) {
        const animationUrl = tokenUri.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/');
        return {
          symbol: await nftContract.symbol(),
          name: `Token #${tokenId}`,
          description: 'No description available.',
          image: '', // Assuming no image is available
          animationUrl: animationUrl,
        };
      }

      tokenUri = tokenUri.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/');
      const response = await fetch(tokenUri);
      if (!response.ok) throw new Error('Failed to fetch NFT metadata');
      const metadata = await response.json();
      const tokenSymbol = await nftContract.symbol();

      // Convert IPFS URLs for image and animationUrl
      const image = metadata.image ? metadata.image.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/') : '';
      const animationUrl = metadata.animation_url ? metadata.animation_url.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/') : '';

      return {
        symbol: tokenSymbol,
        name: metadata.name,
        description: metadata.description,
        image: image,
        animationUrl: animationUrl,
      };
    } catch (error) {
      console.error('Error fetching NFT metadata:', error);
      throw error;
    }
  };



  const mintFactoryNFT = async (_NFTAddress, Cost) => {
    try {
      const signer = await provider.getSigner();
      const NFTContract = new ethers.Contract(_NFTAddress, AGTContract, signer);

      // Check if the contract address is one of the special cases
      const isSpecialContract = contractsWithoutMultipleMint.includes(_NFTAddress);

      let transaction;
      if (isSpecialContract) {
        // If it is a special contract, only send the value
        transaction = await signer.sendTransaction({
          to: _NFTAddress,
          value: ethers.utils.parseEther(Cost.toString())
        });
      } else {
        // If it's not a special contract, proceed with minting
        transaction = await NFTContract.connect(signer).mint(1, { // Assuming '1' is the default quantity
          value: ethers.utils.parseEther(Cost.toString())
        });
      }
      await transaction.wait();

      const tokenId = await NFTContract.totalSupply();
      const metadata = await fetchNftMetadata(NFTContract, tokenId.toString());
      const tokenSymbol = await NFTContract.totalSupply();

      const tokenImage = metadata.image.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/');

      const tokenVideo = metadata.animationUrl.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/');


      // Set detailed NFT information including name, description, and URLs
      setDetailedNftDetails({
        tokenSymbol,
        tokenId: `Token #${tokenId}`,
        name: metadata.name,
        description: metadata.description,
        image: tokenImage,
        animationUrl: tokenVideo,
      });
      setIsDetailedModalOpen(true);

      toast.success('Success!', { className: 'alert-template', position: toast.POSITION.TOP_CENTER, closeButton: false });
    } catch (error) {
      console.error(error);
      toast.error(`Error: ${error.message}`, { className: 'alert-template', closeButton: false });
    }
  };


  const copyFactoryToClipboard = async (NFTAddress) => {
    if (navigator.clipboard) {  // Checking if the Clipboard API is supported
      await navigator.clipboard.writeText(NFTAddress);
      toast.success('Text copied to clipboard');
    } else {
      alert('Clipboard API not supported on your browser');
    }
  };

  const showDetailsModal = async (nft) => {
    // Create a new ethers.Contract instance using the address from the nft object

    const contractInstance = new ethers.Contract(nft.address, MintContract, provider);

    try {
      // Now you can call the totalSupply function on your contract instance
      const totalSupply = await contractInstance.totalSupply();
      const cost = await contractInstance.cost();
      const maxSupply = await contractInstance.maxSupply();
      const metadata = await fetchNftMetadata(contractInstance, totalSupply.toString());

      setDetailedNftDetails({
        address: nft.address,
        totalSupply: nft.totalSupply,
        cost: nft.cost,
        maxSupply: nft.maxSupply,
        maxWallet: nft.maxWallet,
        tokenSymbol: metadata.symbol,
        name: nft.name,
        description: metadata.description,
        image: metadata.image,
        animationUrl: metadata.animationUrl,
      });

      console.log(nft.cost);
      console.log(nft.address)

      setIsDetailedModalOpen(true);
    } catch (error) {
      console.error('Error in showDetailsModal:', error);
      toast.error(`Error: ${error.message}`, { className: 'alert-template', closeButton: false });
    }
  };



  function NFTModal({ isOpen, details, onClose }) {
    if (!isOpen) return null;

    return (
      <div className="modal">
        <div className="modal-content">
          <h2 className='text-header'>{details.name}</h2>
          <video width="400" height="400" controls autoPlay loop muted>
            <source src={nftDetails.artwork} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button className="mint_button" onClick={onClose}>Close</button>
        </div>
      </div>
    );
  }

  function DetailedNFTModal({ isOpen, details, onClose }) {
    if (!isOpen) return null;

    const content = details.animationUrl
      ? (
        <video width="400" height="400" controls autoPlay loop muted className='video-mint'>
          <source src={details.animationUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        details.image && <img src={details.image} alt={details.name} width="400" height="400" />
      );

    return (
      <div className="modal_mint">
        <div className="modal-content_mint" onClick={e => e.stopPropagation()}>
          <h2 className='text-header'>{details.name}</h2>
          {content}
          <div className="info-grid">
            <div>
              <h2 className='text-header'>Total Supply</h2>
              <h3 className='text-body'>{details.maxSupply}</h3>
            </div>
            <div>
              <h2 className='text-header'>NFT Cost:</h2>
              <h3 className='text-body'>{details.cost} PWR</h3> {/* Adjust unit if necessary */}
            </div>
            <div>
              <h2 className='text-header'>Max wallet:</h2>
              <h3 className='text-body'>{details.maxWallet}</h3>
            </div>
            <div>
              <h2 className='text-header'>NFT's left:</h2>
              <span className='text-body'>{details.maxSupply - details.totalSupply}</span>
            </div>
          </div>
          <div className='button-group'>
            <button className="mint_button" onClick={() => handleMintFactoryNFT(details.address, details.cost)}>Mint</button>
            <button className="mint_button" onClick={onClose}>Close</button>
          </div>

          <h1 className='text-transfer'>Or transfer {details.cost} PWR to</h1>
          <h1 className='text-transfer'>the following address:</h1>

          <h1 className='text-transfer'>(Click contract address to copy)</h1>
          <h3 className='text-md_mint ' onClick={() => { copyFactoryToClipboard(details.address) }}>
            {details.address.substring(0, 6)}...{details.address.substring(details.address.length - 4)}
            {/* Assuming you store the contract address in `address` */}
          </h3>
        </div>
      </div>
    );
  }




  useEffect(() => {
    loadBlockchainData();
  }, [provider]);

  useEffect(() => {
    if (account && FactoryContract) {
      updateData();
    }
  }, [account, FactoryContract]);

  return (
    <div>
      <ToastContainer />

      <DetailedNFTModal
        isOpen={isDetailedModalOpen}
        details={detailedNftDetails}
        onClose={() => setIsDetailedModalOpen(false)}
      />


      <div>
        <div>
          <div className='totalArea_mint'>
            {nftContracts.map((nft, index) => (
              // Move the key prop to the first element returned by map
              <div key={index} className='claimArea'>
                <div className='mint_page'>
                  <div onClick={() => showDetailsModal(nft)}>
                    <h2>{nft.symbol}</h2>

                    <video width="300" height="300" controls autoPlay loop muted>
                      <source src={nft.metadata.animationUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <div>
                      <p>Total Supply: {nft.maxSupply}</p>
                      <p>NFT's Left: {nft.maxSupply - nft.totalSupply}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}</div>

        </div>
      </div>
    </div>
  );
}

export default Mint;
