import { useState, useEffect, useContext, useCallback } from 'react';

import { useDropzone } from 'react-dropzone';
import { ethers } from 'ethers';
import AccountContext from '../AccountContext';
import ProviderContext from '../ProviderContext';

import { NFTStorage, File } from 'nft.storage';

import { ToastContainer, toast } from "react-toastify";
import "../components/AlertTemplate.css"

// ABIs
import FactoryABI from '../abis/Factory.json';

// Config
import config from '../config.json';

function Factory() {

    const { account } = useContext(AccountContext);
    const { provider } = useContext(ProviderContext);
    const [FactoryContract, setFactoryContract] = useState(null);

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [newCollectionDetails, setNewCollectionDetails] = useState({
        name: '',
        symbol: '',
        baseURI: '',
        cost: '',
        maxWallet: '',
        maxSupply: '',
        maxMintPerTransaction: '',
        feeRecipient: '',
        image: '' // Assuming you can derive an image URL from the baseURI
    });

    const [file, setFile] = useState(null);
    const [imageFile, setImageFile] = useState(null);


    const [description, setDescription] = useState('');

    const [name, setName] = useState('');
    const [symbol, setSymbol] = useState('');
    const [baseURI, setBaseURI] = useState('');
    const [cost, setCost] = useState('');
    const [maxWallet, setMaxWallet] = useState('');
    const [maxSupply, setMaxSupply] = useState('');
    const [maxMintPerTransaction, setMaxMintPerTransaction] = useState('');
    const [feeRecipient, setFeeRecipient] = useState('');

    const client = new NFTStorage({ token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDdFMDUzMTFBOUVBMjMzY2NjZTE5MGMzNDE2RDhDM0NjNzA5RjExNzEiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTcwNzkzNzQ1NzM2NSwibmFtZSI6IkZhY3RvcnkifQ.AALeKWa37kmKGKmkwK5hzQnT_OBjXxe2FjsQmq175uE' });


    const loadBlockchainData = async () => {
        if (provider) {
            const network = await provider.getNetwork();


            const FactoryContract = new ethers.Contract(
                config[network.chainId].NFTFactory.address,
                FactoryABI,
                provider
            );
            setFactoryContract(FactoryContract);

        }
    }

    // Handle file upload
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const onDropImage = useCallback((acceptedFiles) => {
        console.log("Dropped image file:", acceptedFiles[0]);
        setImageFile(acceptedFiles[0]);
        console.log("Image file state updated:", acceptedFiles[0].name);
    }, []);
    

    const onDropVideo = useCallback((acceptedFiles) => {
        console.log("Dropped video file:", acceptedFiles[0]);
        // Assuming only one video can be uploaded at a time
        setFile(acceptedFiles[0]);
    }, []);


    const {
        getRootProps: getImageRootProps,
        getInputProps: getImageInputProps,
        isDragActive: isImageDragActive,
    } = useDropzone({ onDrop: onDropImage, accept: 'image/*' });

    const {
        getRootProps: getVideoRootProps,
        getInputProps: getVideoInputProps,
        isDragActive: isVideoDragActive,
    } = useDropzone({ onDrop: onDropVideo, accept: 'video/*' });


    const handleCreateCollection = async (collectionDetails, imageFile) => {
        const { name, symbol, cost, maxWallet, maxSupply, maxMintPerTransaction, feeRecipient } = collectionDetails;
        if (!file) {
            toast.error('Please select a video file.');
            return;
        }

        if (!imageFile) {
            toast.error('Please select an image file.');
            return;
        }

        try {
            const client = new NFTStorage({ token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDdFMDUzMTFBOUVBMjMzY2NjZTE5MGMzNDE2RDhDM0NjNzA5RjExNzEiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTcwNzkzNzQ1NzM2NSwibmFtZSI6IkZhY3RvcnkifQ.AALeKWa37kmKGKmkwK5hzQnT_OBjXxe2FjsQmq175uE' });

            // No need to convert video to thumbnail blob anymore
            // const thumbnailBlob = await videoToThumbnailBlob(file);

            // Store metadata with NFT.storage
            const metadata = await client.store({
                name,
                description,
                image: new File([imageFile], imageFile.name, { type: imageFile.type }), // Use the uploaded image file
                animation_url: new File([file], file.name, { type: file.type }), // Use the uploaded video file
            });

            console.log('Metadata CID:', metadata.url);
            toast.success('Files uploaded and metadata created.');

            // Smart contract interaction
            const metadataURI = metadata.url;
            const costInWei = ethers.utils.parseEther(cost);

            const signer = provider.getSigner();
            const tx = await FactoryContract.connect(signer).createCollection(
                name,
                symbol,
                metadataURI,
                costInWei,
                maxWallet,
                maxSupply,
                maxMintPerTransaction,
                feeRecipient
            );

            await tx.wait();
            toast.success('Collection created successfully!');
            setIsCreateModalOpen(false);

        } catch (error) {
            console.error('Error in creating collection:', error);
            toast.error('Failed to create collection.');
        }
    };


    async function videoToThumbnailBlob(videoFile) {
        return new Promise((resolve, reject) => {
            // Create a video element
            const videoElement = document.createElement('video');
            videoElement.setAttribute('src', URL.createObjectURL(videoFile));
            videoElement.load();

            videoElement.addEventListener('loadeddata', () => {
                // Create a canvas element
                const canvas = document.createElement('canvas');
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;

                // Draw the video frame to the canvas
                const ctx = canvas.getContext('2d');
                ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

                // Convert canvas to blob
                canvas.toBlob(blob => {
                    if (blob) {
                        resolve(blob);
                    } else {
                        reject(new Error('Canvas to Blob conversion failed'));
                    }
                }, 'image/jpeg');
            });

            videoElement.addEventListener('error', (event) => {
                reject(new Error('Video loading error'));
            });
        });
    }



    const createCollection = async (name, symbol, baseURI, cost, maxWallet, maxSupply, maxMintPerTransaction, feeRecipient) => {

        try {
            const signer = provider.getSigner();

            // Call the createCollection method
            const tx = await FactoryContract.connect(signer).createCollection(name, symbol, baseURI, ethers.utils.parseEther(cost.toString()), maxWallet, maxSupply, maxMintPerTransaction, feeRecipient);

            // Wait for the transaction to be mined
            await tx.wait();

            console.log('Collection created successfully');
        } catch (error) {
            console.error('Error creating collection:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setNewCollectionDetails({
            name,
            symbol,
            baseURI,
            cost,
            maxWallet,
            maxSupply,
            maxMintPerTransaction,
            feeRecipient,
            image: baseURI // Here, you might need to adjust this to point to a specific image based on your baseURI structure
        });
        setIsCreateModalOpen(true);
    };

    const handleConfirmCreation = async () => {
        await createCollection(
            newCollectionDetails.name,
            newCollectionDetails.symbol,
            newCollectionDetails.baseURI,
            newCollectionDetails.cost,
            newCollectionDetails.maxWallet,
            newCollectionDetails.maxSupply,
            newCollectionDetails.maxMintPerTransaction,
            newCollectionDetails.feeRecipient
        );
        setIsCreateModalOpen(false);
        // Optionally reset form or give feedback to the user
    };

    // A helper function for formatting Ethereum values for display
    const formatEtherValue = (value) => {
        try {
            return ethers.utils.formatEther(value) + " ETH";
        } catch (e) {
            return value; // Return the original value if it cannot be formatted
        }
    };




    function NFTModal({ isOpen, details, onClose, file }) {
        if (!isOpen) return null;

        // Create a URL for the file
        const fileURL = file ? URL.createObjectURL(file) : '';

        // Determine whether the file is a video based on its MIME type
        const isVideo = file && file.type.startsWith('video');

        return (
            <div className="modal">
                <div className="modal-content">
                    <h2 className='text-header'>Confirm New Collection</h2>
                    {
                        isVideo ? (
                            <video src={fileURL} alt={details.name} autoPlay width="400" height="400" controls style={{ marginBottom: "20px" }} />
                        ) : (
                            <img src={fileURL} alt={details.name} width="400" height="400" style={{ marginBottom: "20px" }} />
                        )
                    }
                    <h1 className='text_create'>Name: {details.name}</h1>
                    <h1 className='text_create'>Symbol:{details.symbol}</h1>
                    <h1 className='text_create'>Description:{details.description}</h1>
                    <h1 className='text_create'>Cost:{formatEtherValue(details.cost)}</h1>
                    <h1 className='text_create'>Max Wallet:{details.maxWallet}</h1>
                    <h1 className='text_create'>Max Supply: {details.maxSupply}</h1>
                    <h1 className='text_create'>Max Mint Per Transaction: {details.maxMintPerTransaction}</h1>
                    <h1 className='text_create'>Fee Recipient: {details.feeRecipient.substring(0, 6)}...{details.feeRecipient.substring(details.feeRecipient.length - 4)}</h1>
                    <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-around" }}>
                        <button className="mint_button" onClick={() => handleCreateCollection(details, imageFile)}>Confirm</button>
                        <button className="mint_button" onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>
        );
    }


    useEffect(() => {
        loadBlockchainData();
    }, [provider]);

    return (
        <div>
            <ToastContainer />

            <NFTModal
                isOpen={isCreateModalOpen}
                details={newCollectionDetails}
                onClose={() => setIsCreateModalOpen(false)}
                onConfirm={handleConfirmCreation}
                file={file} // Pass the file here
            />


            <div className="claimArea-grid">
                <div className="claimArea">
                    <div className="mint">
                        <h1 className='header'>Create New NFT Collection</h1>
                        <form onSubmit={handleSubmit} className="info-grid">
                            <div>
                                <h2 className='text-header'>Name</h2>
                                <input type="text" placeholder="Collection Name" className='input_create' value={name} onChange={e => setName(e.target.value)} />
                            </div>
                            <div>
                                <h2 className='text-header'>Symbol</h2>
                                <input type="text" placeholder="Symbol" className='input_create' value={symbol} onChange={e => setSymbol(e.target.value)} />
                            </div>
                            <div>
                                <h2 className='text-header'>Description</h2>
                                <input type="text" placeholder="Description" className='input_create' value={description} onChange={e => setDescription(e.target.value)} />
                            </div>
                            <div>
                                <h2 className='text-header'>Cost</h2>
                                <input type="text" placeholder="Cost in ETH" className='input_create' value={cost} onChange={e => setCost(e.target.value)} />
                            </div>
                            <div>
                                <h2 className='text-header'>Max Wallet</h2>
                                <input type="number" placeholder="Max Wallet" className='input_create' value={maxWallet} onChange={e => setMaxWallet(e.target.value)} />
                            </div>
                            <div>
                                <h2 className='text-header'>Max Supply</h2>
                                <input type="number" placeholder="Max Supply" className='input_create' value={maxSupply} onChange={e => setMaxSupply(e.target.value)} />
                            </div>
                            <div>
                                <h2 className='text-header'>Max Mint Per Transaction</h2>
                                <input type="number" placeholder="Max Mint/Transaction" className='input_create' value={maxMintPerTransaction} onChange={e => setMaxMintPerTransaction(e.target.value)} />
                            </div>
                            <div>
                                <h2 className='text-header'>Fee Recipient</h2>
                                <input type="text" placeholder="Fee Recipient Address" className='input_create' value={feeRecipient} onChange={e => setFeeRecipient(e.target.value)} />
                            </div>
                            // Image Drop Zone
<div {...getImageRootProps()} className={`dropzone ${isImageDragActive ? 'active' : ''}`}>
    <input {...getImageInputProps()} />
    {
        imageFile
        ? <p>File selected: {imageFile.name}</p> // Display this when an image file is selected
        : isImageDragActive
            ? <p>Drop the image file here...</p> // Display this when an image file is being dragged over the dropzone
            : <p>Drag 'n' drop an image file here, or click to select a file</p> // Display this as the default message for the image drop zone
    }
</div>

// Video Drop Zone
<div {...getVideoRootProps()} className={`dropzone ${isVideoDragActive ? 'active' : ''}`}>
    <input {...getVideoInputProps()} />
    {
        file
        ? <p>File selected: {file.name}</p> // Display this when a video file is selected
        : isVideoDragActive
            ? <p>Drop the video file here...</p> // Display this when a video file is being dragged over the dropzone
            : <p>Drag 'n' drop a video file here, or click to select a file (Optional)</p> // Display this as the default message for the video drop zone, indicating that video upload is optional
    }
</div>

                            <div className="center-button">
                                <button type="submit" className="mint_button">
                                    Create Collection
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default Factory;
