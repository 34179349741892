import { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import AccountContext from '../AccountContext';
import ProviderContext from '../ProviderContext';
import axios from 'axios';

import { ToastContainer, toast } from "react-toastify";
import "../components/AlertTemplate.css"

// ABIs
import PairContract from '../abis/Pair.json';
import TokenContract from '../abis/token.json'
import RewardContract from '../abis/reward.json';


// Config
import config from '../config.json';
import tokenList from './tokenList';

function Claim() {
  const [pairContract, setPairContract] = useState('');
  const [tokenContract, setTokenContract] = useState('');
  const [rewardContract, setRewardContract] = useState('');
  const { account } = useContext(AccountContext);
  const { provider } = useContext(ProviderContext);
  const [price, setPrice] = useState(0);
  const [reserve0, setReserve0] = useState(0);
  const [reserve1, setReserve1] = useState(0);
  const [isFlipped, setIsFlipped] = useState(true);;
  const [userPWRBalance, setUserPWRBalance] = useState(null);
  const [userTokenBalance, setUserTokenBalance] = useState(null);

  const [totalSupply, setTotalSupply] = useState(null);
  const [circSupply, setCircSupply] = useState(null);
  const [deadBalance, setDeadBalance] = useState(null);
  const [unpaidEarnings, setUnpaidEarnings] = useState(null);

  const [firepitValue, setFirepitValue] = useState(null);
  const [userTokenValue, setUserTokenValue] = useState(null);
  const [totalRewards, setTotalRewards] = useState(null);

  const [userShares, setUserShares] = useState(null);
  const [userTotalRewards, setUserTotalRewards] = useState(null);


  const loadBlockchainData = async () => {
    if (provider) {
      const network = await provider.getNetwork();
      const signer = provider.getSigner();

      const pairContract = new ethers.Contract(
        config[network.chainId].Pair.address,
        PairContract,
        provider
      );
      setPairContract(pairContract);

      const tokenContract = new ethers.Contract(
        config[network.chainId].Token.address,
        TokenContract,
        signer
      );
      setTokenContract(tokenContract);

      const rewardContract = new ethers.Contract(
        config[network.chainId].Reward.address,
        RewardContract,
        signer
      );
      setRewardContract(rewardContract);
    }
  };

  const updateData = async () => {
    fetchReserves();
    fetchEthBalance();
    fetchTokenBalance();
    fetchSupply();
    fetchCirculation();
    fetchFirePit();
    fetchUnpaid();
    fetchTotalRewards();
  };

  const calcFirepitValue = async () => {
    const firepit = await price * deadBalance;
    const firepitRounded = parseFloat(firepit).toFixed(5);
    setFirepitValue(firepit);
  }

  const calcUserTokenValue = () => {
    const userValue = userTokenBalance * price;
    const userValueRounded = (userValue);
    setUserTokenValue(userValueRounded);
  }

  const handleClaim = async () => {
    const tx = await rewardContract.claimDividend()
  }



  const flipTokens = () => {
    setIsFlipped(!isFlipped);
  };

  const fetchEthBalance = async () => {
    const balance = await provider.getBalance(account);
    setUserPWRBalance(parseFloat(ethers.utils.formatEther(balance)).toFixed(5));

  };

  const fetchSupply = async () => {
    const supply = await tokenContract._totalSupply();
    setTotalSupply(ethers.utils.formatUnits(supply, 5));

  };

  const fetchCirculation = async () => {
    const calcCircSupply = await tokenContract.getCirculatingSupply();
    setCircSupply(ethers.utils.formatUnits(calcCircSupply, 5));

  };

  // Function to fetch Token balance
  const fetchTokenBalance = async () => {
    const balance = await tokenContract.balanceOf(account);
    const balanceRounded = ethers.utils.formatUnits(balance, 5);
    setUserTokenBalance(balanceRounded); // Assuming 5 decimals in the token

  };

  const fetchFirePit = async () => {
    const balance = await tokenContract.balanceOf('0x000000000000000000000000000000000000dEaD');
    setDeadBalance(ethers.utils.formatUnits(balance, 5)); // Assuming 5 decimals in the token
  };

  const fetchUnpaid = async () => {
    const getUnpaid = await rewardContract.getUnpaidEarnings(account);
    const unpaid = ethers.utils.formatEther(getUnpaid);
    const unpaidEarningsNumber = parseFloat(unpaid); // Convert string to number
    const unpaidEarningsRounded = unpaidEarningsNumber.toFixed(5); // Round to up to 5 decimal places

    setUnpaidEarnings(unpaidEarningsRounded);

  };

  const fetchShares = async () => {
    const getShareInfo = await rewardContract.shares(account);
    const userSharesAmount = parseFloat(getShareInfo[0]);

    const userTotalRewardsEarned = parseFloat(ethers.utils.formatEther(getShareInfo[2]));
    setUserTotalRewards((userTotalRewardsEarned).toFixed(5));

    const imbalance = (userTokenBalance / userSharesAmount * 10 ** 7 - 100).toFixed(2);

    setUserShares(imbalance);

  };

  const calculateOutputAmount = (inputAmount, reserve0, reserve1, slippage, isEthToToken) => {
    let idealOutput;

    if (isEthToToken) {
      // Calculating how much ERC20 token you'll get for the given amount of ETH
      idealOutput = (inputAmount * reserve1) / reserve0;
    } else {
      // Calculating how much ETH you'll get for the given amount of ERC20 token
      idealOutput = (inputAmount * reserve0) / reserve1;
    }

    let outputLowerBound = idealOutput;

    // Convert the outputLowerBound to a Number and limit to 5 decimal places
    outputLowerBound = Number(Number(outputLowerBound).toFixed(5));

    return outputLowerBound;
  };

  const getPrice = async () => {
    try {
      const response = await axios.get('/api/getPriceGecko');

      if (response.data && response.data.data && response.data.data[0] && response.data.data[0].attributes) {
        const priceValue = response.data.data[0].attributes.base_token_price_usd;
        console.log(priceValue);
        setPrice(priceValue); // now storing only the price value, not the entire object
      } else {
        console.error('Unexpected response format.');
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const fetchReserves = async () => {
    // Assume that 'pairContract' can provide the reserve info for the pair
    if (pairContract) {
      try {
        const reserves = await pairContract.getReserves();
        // Convert BigNumber to String
        const reserve0String = reserves.reserve0.toString();
        const reserve1String = reserves.reserve1.toString();

        // Convert to normalized JavaScript Number for display (may lose precision)
        const reserve0Normalized = Number(ethers.utils.formatUnits(reserve0String, 5));
        const reserve1Normalized = Number(ethers.utils.formatUnits(reserve1String, 18));

        // Store them in state for use in UI or further calculations
        setReserve0(reserve0Normalized);
        setReserve1(reserve1Normalized);

      } catch (e) {
        console.error('Error fetching reserves', e);
      }
    }
  };

  const fetchTotalRewards = async () => {
    const totalRewardsPaid = await rewardContract.totalDistributed();
    const totalRewardsEth = ethers.utils.formatEther(totalRewardsPaid);
    const totalRewardsNumber = parseFloat(totalRewardsEth); // Convert string to number
    const totalRewardsRoundedRounded = totalRewardsNumber.toFixed(5); // Round to up to 5 decimal places
    setTotalRewards(totalRewardsRoundedRounded);
  }



  useEffect(() => {
    loadBlockchainData();
  }, [provider]);

  useEffect(() => {
    if (account && tokenContract && rewardContract) {
      updateData();
      getPrice();
    }
  }, [account, tokenContract, RewardContract]);

  useEffect(() => {
    if (price !== 0 && userTokenBalance !== 0 && deadBalance !== 0) {
      calcFirepitValue();
      calcUserTokenValue();
      fetchShares();
    }
  }, [price, userTokenBalance, deadBalance]);

  return (
    <div>
      <ToastContainer />
      <div>
      
        <div className='dashboardContainer'>
          <div className='dashboard'>
            <div className='claimArea'>
              <h1 className='text-md'>Token Balance:</h1>
              <h2 className="text-body">{userTokenBalance} SafuMaxx</h2>
            </div>
          </div>
          <div className='dashboard'>
            <div className='claimArea'>
              <h1 className='text-md'>User Token Value:</h1>
              <h2 className="text-body">${parseFloat(userTokenBalance * price).toFixed(2)}</h2>
            </div>
          </div>
          <div className='dashboard'>
            <div className='claimArea'>
              <h1 className='text-md'>User PWR Balance:</h1>
              <h2 className="text-body">{userPWRBalance} PWR</h2>
            </div>
          </div>
          <div className='dashboard'>
            <div className='claimArea'>
              <h1 className='text-md'>SafuMaxx Price:</h1>
              <h2 className="text-body">${parseFloat(price).toFixed(5)}</h2>
            </div>
          </div>
          <div className='dashboard'>
            <div className='claimArea'>
              <h1 className='text-md'>Total User Rewards:</h1>
              <h2 className="text-body">{userTotalRewards} PWR</h2>
            </div>
          </div>
          <div className='dashboard'>
            <div className='claimArea'>
              <button className='claimButton' onClick={handleClaim}>Claim Rewards</button>
            </div>
          </div>
          <div className='dashboard'>
            <div className='claimArea'>
              <h1 className='text-md'>Unpaid Rewards:</h1>
              <h2 className="text-body">{unpaidEarnings} PWR</h2>
            </div>
          </div>
          <div className='dashboard'>
            <div className='claimArea'>
              <h1 className='text-md'>Total PWR Rewards:</h1>
              <h2 className="text-body">{totalRewards} PWR</h2>
            </div>
          </div>
          <div className='dashboard'>
            <div className='claimArea'>
              <h1 className='text-md'>Total Supply:</h1>
              <h2 className="text-body">{totalSupply} SafuMaxx</h2>
            </div>
          </div>
          <div className='dashboard'>
            <div className='claimArea'>
              <h1 className='text-md'>Circulating Supply:</h1>
              <h2 className="text-body">{circSupply} SafuMaxx</h2>
            </div>
          </div>
          <div className='dashboard'>
            <div className='claimArea'>
              <h1 className='text-md'>Fire Pit Amount:</h1>
              <h2 className="text-body">{deadBalance} SafuMaxx</h2>
            </div>
          </div>
          <div>
            <div className='dashboard'>
              <div className='claimArea'>
                <h1 className='text-md'>Fire Pit Value:</h1>
                <h2 className='text-body'>${parseFloat(deadBalance * price).toFixed(2)}</h2>
              </div>
            </div>
          </div>
          <div>
            <div className='dashboard'>
              <div className='claimArea'>
                <h1 className='text-md'>Fire Pit Ratio:</h1>
                <h2 className='text-body'>{(parseFloat(deadBalance / totalSupply) * 100).toFixed(2)}%</h2>
              </div>
            </div>
          </div>
          <div className='dashboard'>
            <div className='claimArea'>
              <h1 className='text-md'>Dividend Imbalance:</h1>
              <h2 className='text-body'>{userShares}%</h2>
            </div>
          </div>
        </div>
      </div>



    </div>
  );
}

export default Claim;
